import * as d3 from "d3";
import { initCrosshair, addCrosshairEventHandlers } from ".//d3Crosshair";
import { buildBrushAndZoom } from ".//d3BrushAndZoom";

export function initChart(chartDimensions) {
  const svg = d3.select(".chart-svg");
  const { margin } = chartDimensions;

  const chart = svg
    .append("g")
    .attr("class", "chart")
    .attr("transform", `translate(${margin.left}, ${margin.top})`);

  chart
    .append("g")
    .attr("class", "chart-header")
    .attr("transform", `translate(0, ${-margin.top * 0.4})`)
    .append("text")
    .attr("class", "chart-header-text");

  // clip path to hide plot lines that extend outside of the chart area during zoom
  chart
    .append("clipPath")
    .attr("id", "clip-path")
    .append("rect")
    .attr("x", 1); //x is 1 so that the clip path ends just before the y axis line

  chart
    .append("g")
    .attr("class", "plot-group")
    .style("clip-path", "url(#clip-path)");

  chart.append("g").attr("class", "x axis");

  chart.append("g").attr("class", "y axis");

  chart.append("rect").attr("class", "chart-overlay") 

  initCrosshair(chart);

  // context contains a mini-plot of the line series and also a brush to change/indicate the current pan-zoom
  chart
    .append("g")
    .attr("class", "context")
    .append("g")
    .attr("class", "brush");

  svg
    .append("text")
    .attr("class", "no-data")
    .text("No Data");
}

export function updateChart(data, chartDimensions, isResizing) {

  const hasData = data.series.length !== 0;
  setChartVisibility (hasData);

  const transition = d3.transition().duration(isResizing? 0 : 850); // zero length transition during resizing

  const contextHeight = chartDimensions.margin.bottom - 40 , contextBorder = 1.5;

  const [xScale, yScale, xScaleContext, yScaleContext] 
    = buildScales(data, chartDimensions, contextHeight, contextBorder)

  const [xAxisDraw, xAxis] = drawAxes(xScale, yScale, chartDimensions, transition)

  const lineGen = lineGenerator(xScale, yScale);
  const contextLineGen = lineGenerator(xScale, yScaleContext);

  if (isResizing) {

    resizeChart(chartDimensions, contextHeight)

    d3
      .selectAll(".plot-group .line-series")
      .attr("d", (d) => lineGen(d.values));

    d3
      .selectAll(".context  .line-series")
      .attr("d", (d) => contextLineGen(d.values));
  }
  else{

    generateAndSetHeaderText(data);
    plotSeries(data, d3.select(".plot-group"), lineGen, transition);
    plotSeries(data, d3.select(".context"), contextLineGen, transition);  
  
  }
  
  if (!hasData) return;

  addCrosshairEventHandlers(xScale, yScale, chartDimensions);
  buildBrushAndZoom(chartDimensions, contextHeight, 
    xScale, xScaleContext, xAxisDraw, xAxis, lineGen, transition);
}

export function resizeChart(chartDimensions, contextHeight) {

  const svg = d3.select(".chart-svg");
  const chart = d3.select(".chart");
  const {height, width, margin} = chartDimensions;

  svg
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom);

  chart
    .selectAll("clipPath rect, .chart-overlay")
    .attr("width", width)
    .attr("height", height);

  svg.select(".no-data")
    .attr("x", (width + margin.left + margin.right - 100) / 2)
    .attr("y", (height + margin.top + margin.bottom) / 2)
 
  d3.select(".x.axis").attr("transform", "translate(0," + height + ")");

  const context = chart.select(".context")
  context.attr("transform", `translate(${0}, ${height + 30})`);
  context.select(".overlay").attr("width", width)
  context.select(".overlay").attr("height", contextHeight)
}

function generateAndSetHeaderText(data) {

  const header = d3.select(".chart-header-text")
  header.text("Daily COVID-19 Cases in ");
  const seriesLength = data.series.length - 1;

  data.series.forEach((s, i) => {

    if (i === seriesLength && seriesLength > 0) header.append("tspan").text(" and ");
    
    header.append("tspan").style("fill", s.color).text(s.name);
    
    if (i < seriesLength - 1) header.append("tspan").text(", ");
  
  });
}

function buildScales(data, chartDimensions, contextHeight, contextBorder) {

  const { width, height } = chartDimensions;

  const xScale = d3
    .scaleTime()
    .domain([data.xMin, data.xMax])
    .range([0, width])
    .nice();

  const yScale = d3
    .scaleLinear()
    .domain([data.yMin, data.yMax])
    .range([height, 0])
    .nice();

  // account for the border width of the context area
  // so the line series dont overlap the border (on x and y)
  const xScaleContext = d3
    .scaleTime()
    .domain([data.xMin, data.xMax])
    .range([contextBorder, width - contextBorder])
    .nice();

  const yScaleContext = d3
    .scaleLinear()
    .domain([data.yMin, data.yMax])
    .range([contextHeight - contextBorder, contextBorder]) 
    .nice();

  return [xScale, yScale, xScaleContext, yScaleContext];
}

function drawAxes(xScale, yScale, chartDimensions, transition) {

  const { width, height } = chartDimensions;
  const xAxisDraw = d3.select(".x.axis");
  const xAxis = d3.axisBottom(xScale).ticks(Math.max((width/100),2))

  xAxisDraw.transition(transition).call(xAxis);

  const yAxis = d3
    .axisLeft(yScale)
    .ticks(Math.max((height/50),2))
    .tickSizeInner(-width);

  d3
    .select(".y.axis")
    .transition(transition)
    .call(yAxis)
    .selectAll("text")
    .attr("dx", "-0.5em"); // nudge the labels left

    return [xAxisDraw, xAxis];
}

function lineGenerator(xScale, yScale) {
  return d3
    .line()
    .x((d) => xScale(d.date))
    .y((d) => yScale(d.value));
}

function plotSeries(data, targetChart, generator, t) {

  targetChart
    .selectAll(".line-series")
    .data(data.series, (d) => d.name)
    .join(
      (enter) => {
        enter
          .append("path")
          .style("stroke-opacity", "0")
          .attr("class", (d) => `line-series ${d.name.toLowerCase()}`)
          .transition(t)
          .style("stroke-opacity", "0.9")
          .attr("d", (d) => generator(d.values))
          .style("stroke", (d) => d.color);
      },
      (update) => {
        update
          .transition(t)
          .attr("d", (d) => generator(d.values))
          .style("stroke-opacity", "0.9"); // update stroke opacity for edge cases where user added two series in quick succession and opacity transition was interrupted
      },
      (exit) => exit.remove()
    );
}

function setChartVisibility (hasData) {
  
  d3
    .select(".chart")
    .attr("visibility", () => (hasData ? "visible" : "hidden"));
  
  d3
    .select(".no-data")
    .attr("visibility", () => (hasData ? "hidden": "visible"));
  }