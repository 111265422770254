import React, { useMemo, useState } from "react";
import "./App.css";
import ChartLoader from "./components/ChartLoader";

require("axios");

const data = ["Westminster", "Lancaster", "Highland", "Liverpool"];

export default function App() {
  const [cities, setCities] = useState(data.map((city, i) => ({ name: city, checked: i === 0 })));

  const filteredCities = useMemo(() => {
    return cities.map((city) => (city.checked ? city.name : undefined)).filter((d) => d);
  }, [cities]);
  return (
    <React.Fragment>
    <ChartLoader cities={filteredCities} />
    <div id="city-checkboxes" style={{marginLeft: '60px'}}>
        {cities.map((city, i) => (
          <React.Fragment key={city.name}>
            <label style={{fontSize: '14px'}}>
            <input
              type="checkbox"
              name={city.name}
              onChange={() =>
                setCities((prev) => {
                  const arr = [...prev];
                  arr[i] = { ...arr[i], checked: !arr[i].checked };

                  return arr;
                })
              }
              value={city.name}
              checked={city.checked}
            />
            {city.name} </label>
          </React.Fragment>
        ))}
      </div>
      </React.Fragment>
  );
}

