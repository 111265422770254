import React, { useEffect, useState } from "react";
import { initChart, updateChart } from "../d3//d3Chart";

function Chart({ data }) {

  const margin = { top: 60, right: 40, bottom: 140, left: 60 };

  const box = document.querySelector('#root');
  const initialWidth = box.clientWidth;
  const initialHeight = box.clientHeight;

  const [firstLoad, setFirstLoad] = useState(true);
  const [clientWidth, setClientWidth] = useState(initialWidth);
  const [clientHeight, setClientHeight] = useState(initialHeight);

  function d3MarginConvention(clientWidth, clientHeight){

    const CHART_RELATIVE_HEIGHT = 0.92;
    const MARGIN_BOTTOM_RELATIVE_HEIGHT = 0.20;

    margin.bottom = (clientHeight * CHART_RELATIVE_HEIGHT) * MARGIN_BOTTOM_RELATIVE_HEIGHT
    const width = clientWidth - margin.left - margin.right;
    const height = clientHeight * CHART_RELATIVE_HEIGHT - margin.top - margin.bottom; 

    return {width, height, margin};
  }

  useEffect(() => {
    if (data) {
      const chartDimensions = d3MarginConvention(clientWidth, clientHeight);
      if (firstLoad) initChart(chartDimensions);
      setFirstLoad(false);
      updateChart(data, chartDimensions);
    }
  }, [data]);

  useEffect(() => {
    const resizer = new ResizeObserver((entries) => {
      if (entries[0]) {
        const { contentRect } = entries[0];
        if (contentRect.width < 200 || contentRect.height < 250 ) return;
        if (clientWidth!==contentRect.width || clientHeight!==contentRect.height)
        {
          const chartDimensions = d3MarginConvention(contentRect.width, contentRect.height);
          updateChart(data, chartDimensions, true);  
        }
        
        setClientWidth(contentRect.width);
        setClientHeight(contentRect.height);
      }
    });

    resizer.observe(document.querySelector("#root"));
    return () => resizer.disconnect();

  });
  return <svg className="chart-svg"></svg>;
}

export default Chart;
