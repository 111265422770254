import * as d3 from "d3";
import { toggleElementVisability } from ".//lib";
  
export function buildBrushAndZoom(chartDimensions, contextHeight, xScale, xScaleContext, xAxisDraw, xAxis, lineGen, transition) {

    const { width, height } = chartDimensions;

    const plotGroup = d3.select(".plot-group");
    const mouseOverlay = d3.select(".chart-overlay")

    const brush = d3
      .brushX()
      .extent([
        [0, 0],
        [width, contextHeight]
      ])
      .on("start", brushStarted)
      .on("brush end", brushed);

    function brushStarted() {
        setHandleSize(contextHeight);
    }

    function brushed(e) {

        if (!e.sourceEvent || e.sourceEvent.type === "zoom") return; // ignore brush-by-zoom

        const selection = e.selection || xScaleContext.range();

        xScale.domain(selection.map(xScaleContext.invert, xScaleContext));

        plotGroup.selectAll(".line-series").attr("d", (d) => lineGen(d.values));
        // regenerate the line series with updated x scale
        xAxisDraw.call(xAxis);

        d3.select(".chart-overlay").call(
            zoom.transform,
                d3.zoomIdentity.scale(width / (selection[1] - selection[0]))
                    .translate(-selection[0], 0)
        );

        setHandleSize(contextHeight);
    }

    const zoom = d3
        .zoom()
        .scaleExtent([1, 100])
        .translateExtent([
        [0, 0],
        [width, height]
        ])
        .extent([
        [0, 0],
        [width, height]
        ])
        .on("zoom", zoomed);

    function zoomed(e) {

        if (!e.sourceEvent || e.sourceEvent.type === "brush") return; // ignore zoom-by-brush

        const zoomState = e.transform;
        const zoomFactor = Math.round((zoomState.k + Number.EPSILON) * 100) / 100; // e.g. round 1.000005 to 1

        if (e.sourceEvent.type === "mousemove") {
            toggleElementVisability(d3.select(".crosshair"), 0);
            if (zoomFactor !== 1) d3.select(this).style("cursor", "pointer"); // show pointer cursor during chart pan
        }

        const newXScale = zoomState.rescaleX(xScaleContext);
        xScale.domain(newXScale.domain());

        // regenerate the line series with updated x scale
        xAxisDraw.call(xAxis);

        plotGroup
            .selectAll(".line-series")
            .attr("d", (d) => lineGen(d.values));

        // move the brush selection on the context indicator to match the new zoom
        d3.select(".context,brush")
            .call(brush.move, xScale.range()
                .map(zoomState.invertX, zoomState)
                );

        if (zoomFactor === 1) collapseBrush();

        setHandleSize(contextHeight);
    }

    function collapseBrush() {d3.select(".context").call(brush.move, null);}

    d3.select(".context,brush").call(brush)
    mouseOverlay.call(zoom);

    d3.select("body")
        .on("keydown", (event) => {
            if (event.keyCode === 27) {
                resetZoom(d3.transition().duration(850));
                plotGroup
                    .selectAll(".line-series")
                    .transition().duration(850)
                    .attr("d", (d) => lineGen(d.values));
            }
    });

    function resetZoom(transition) {
        zoom.scaleTo(mouseOverlay, 1);
        xScale.domain(xScaleContext.domain());
        xAxisDraw
            .transition(transition)
            .call(d3.axisBottom(xScale)
            .ticks(Math.max((width / 100), 2)));
        collapseBrush();
    }
    resetZoom(transition);
}

// customise brush handle dimensions 
export function setHandleSize(contextHeight) {
    d3
      .selectAll(".handle--e, .handle--w ")
      .attr("y", 0.5)
      .attr("width", 4)
      .attr("height", contextHeight - 1);
  }