import React, { useEffect, useState } from "react";
import * as d3 from "d3";
import Chart from "./Chart";

const endpoint = (city) => {
  return (
    "https://api.coronavirus.data.gov.uk/v1/data?" +
    `filters=areaType=ltla;areaName=${city}&` +
    'structure={"date":"date","newCases":"newCasesBySpecimenDate"}'
  );
};

function prepareLineChartData(data, cities) {

  // slice off 1st datapoint for newCasesBySpecimenDate as there's no data for current day
  data = data.map((d) => d.slice(1));

  // accumulate x values from all series to get xMin and xMax
  const allX = data.reduce((accumulator, d) => [...accumulator, ...d.map((n) => new Date(n.date))], []);
  const [xMin, xMax] = d3.extent(allX);

  // accumulate y values from all series to get yMax
  const allY = data.reduce((accumulator, d) => [...accumulator, ...d.map((n) => n.newCases)], []);
  const [yMin, yMax] = d3.extent(allY);

  // definition of the series data
  const lineData = {
    series: data.map((d, i) => ({
      name: cities[i],
      color: getColour(cities[i]),
      values: d.map((d) => ({
        date: new Date(d.date),
        value: d.newCases
      }))
    })),
    xMin,
    xMax,
    yMin,
    yMax
  };
  return lineData;
}

const cityDataCache = {};

export default function ChartLoader({ cities }) {
  const [loading, setLoading] = useState(false);
  const [lineData, setData] = useState(null);

  useEffect(() => {
    if (lineData === null) setLoading(true);

    Promise.all(
      cities.map((city) => {
        if (cityDataCache[city]) return cityDataCache[city];
        return fetch(endpoint(city))
          .then((response) => response.json())
          .then((data) => {
            cityDataCache[city] = data;
            return data;
          });
      })
    ).then((data) => {
      // for multiple data series with a different data structure prepareLineChartData function may need to be refactored
      const lineData = prepareLineChartData(data.map((d) => d.data), cities);

      setData(lineData);
      setLoading(false);
    });
  }, [cities]);

  let cityList = "";
  cities.forEach((city) => (cityList += city + ", "));

  return (
    <React.Fragment>
      {loading ? null : (
        <Chart data={lineData} />
      )}
    </React.Fragment>
  );
}

function getColour(city)
{
  const colours = ["#feb236", "#6b5b95", "#ff7b25", "#d64161"];

  switch (city) {
    case "Westminster":
      return colours[0]
    case "Lancaster":
      return colours[1]
    case "Highland":
      return colours[2]
    case "Liverpool":
      return colours[3]
    default:
      break;
  }
}