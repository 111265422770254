import * as d3 from "d3";
import { format } from "date-fns";
import { toggleElementVisability } from ".//lib";

export function initCrosshair(chart) {

    const crosshair = chart.append("g").attr("class", "crosshair").style("opacity", 0);

    // create labels first so text will be rendered on top
    crosshair
      .append("rect")
      .attr("width", 67)
      .attr("height", 15)
      .attr("class", "x crosshair-label-background");
  
    crosshair
      .append("rect")
      .attr("width", 24.5)
      .attr("height", 15)
      .attr("class", "y crosshair-label-background");
  
    crosshair.append("text").attr("class", "x crosshair-text");
  
    crosshair
      .append("text")
      .attr("class", "y crosshair-text")
      .attr("dy", "0.32em")
      .attr("dx", "-0.5em");
  
    crosshair.append("line").attr("class", "x crosshair-line");
  
    crosshair.append("line").attr("class", "y crosshair-line");
}

export function updateCrosshair(event, xScale, yScale, height, width) {

    let pointerXY = d3.pointer(event);
    let mouseXValue = xScale.invert(pointerXY[0]);
    let mouseYValue = yScale.invert(pointerXY[1]);
  
    d3.select(".x.crosshair-label-background")
      .attr("x", pointerXY[0] - 31.5)
      .attr("y", height + 5);
  
    d3.select(".x.crosshair-text")
      .attr("x", pointerXY[0] + 2)
      .attr("y", height + 16)
      .html(format(new Date(mouseXValue), "dd MMM yyyy"));
  
    d3.select(".x.crosshair-line")
      .attr("x1", pointerXY[0])
      .attr("x2", pointerXY[0])
      .attr("y1", 0)
      .attr("y2", height);

    d3.select(".y.crosshair-label-background")
      .attr("x", -31.5)
      .attr("y", pointerXY[1] - 7.9);
  
    d3.select(".y.crosshair-text")
      .attr("x", -14)
      .attr("y", pointerXY[1])
      .html(mouseYValue.toFixed(0));
  
    d3.select(".y.crosshair-line")
      .attr("x1", 0)
      .attr("x2", width)
      .attr("y1", pointerXY[1])
      .attr("y2", pointerXY[1]);
  }
  
  export function addCrosshairEventHandlers(xScale, yScale, chartDimensions) {

    const { width, height } = chartDimensions;
    const crosshair = d3.select(".crosshair");
  
    function pointermove(event) {
      
      d3.select(this).style("cursor", "default");
      let pointerXY = d3.pointer(event);

      const pointerIsOutsideOverlay 
        = (pointerXY[0] < 0 || pointerXY[0] > width || pointerXY[1] < 0 || pointerXY[1] > height);

      if (pointerIsOutsideOverlay) {
        toggleElementVisability(crosshair, 0);
        return
      }

      toggleElementVisability(crosshair, 1);
      updateCrosshair(event, xScale, yScale, height, width);
    }

    d3
      .select(".chart-overlay")
      .on("pointermove", pointermove)
      .on("mouseout pointerup", () => toggleElementVisability(crosshair, 0));
  }
